import React, { useState, useEffect, useCallback, useContext } from 'react';
import HotelCard from './HotelCard';
import { SortContext } from '../App';

const SortOptions = {
  PRICE_LOW: { value: 'cashPrice', order: 'ASC', label: 'Price (Low to High)' },
  PRICE_HIGH: { value: 'cashPrice', order: 'DESC', label: 'Price (High to Low)' },
  VALUE_HIGH: { value: 'valuePerPoint', order: 'DESC', label: 'Best Value (High to Low)' },
  VALUE_LOW: { value: 'valuePerPoint', order: 'ASC', label: 'Worst Value (Low to High)' },
  NAME_AZ: { value: 'name', order: 'ASC', label: 'Hotel Name (A-Z)' },
  NAME_ZA: { value: 'name', order: 'DESC', label: 'Hotel Name (Z-A)' },
  BRAND_AZ: { value: 'brand', order: 'ASC', label: 'Brand (A-Z)' }
};

const HotelCards = ({ hotels }) => {
  // Use the sort context for accessing and updating sort state
  const sortContext = useContext(SortContext);
  
  // Find the matching sort option key based on the current sortBy and sortOrder values from context
  const getSortOptionFromContext = useCallback(() => {
    if (!sortContext.sortBy || !sortContext.sortOrder) return 'VALUE_HIGH'; // Default
    
    // Find the option key that matches both the sort field and order
    const matchingOption = Object.entries(SortOptions).find(([_, config]) => 
      config.value === sortContext.sortBy && config.order === sortContext.sortOrder
    );
    
    return matchingOption ? matchingOption[0] : 'VALUE_HIGH';
  }, [sortContext.sortBy, sortContext.sortOrder]);
  
  // Initialize with the current sort from context
  const [sortOption, setSortOption] = useState(() => getSortOptionFromContext());
  
  // Update local state when context changes
  useEffect(() => {
    const newSortOption = getSortOptionFromContext();
    setSortOption(newSortOption);
    console.log('Updated sort option from context:', newSortOption);
  }, [sortContext.sortBy, sortContext.sortOrder, getSortOptionFromContext]);
  
  // Debug: Log the hotels array and sort options when component renders
  console.log('HotelCards received hotels:', hotels);
  console.log('Hotels array length:', hotels ? hotels.length : 0);
  console.log('Current sort configuration:', { 
    sortOption, 
    contextSortBy: sortContext.sortBy, 
    contextSortOrder: sortContext.sortOrder 
  });
  
  // Handle sort change by updating the context
  const handleSortChange = useCallback((e) => {
    const newSortOption = e.target.value;
    setSortOption(newSortOption);
    
    const sortConfig = SortOptions[newSortOption];
    console.log('Changing sort to:', sortConfig);
    
    // Update the sort context which will trigger a search
    sortContext.updateSort(sortConfig.value, sortConfig.order);
  }, [sortContext]);
  
  return (
    <div className="results-container">
      <div className="sort-controls">
        <label htmlFor="sort-select">Sort by:</label>
        <select 
          id="sort-select"
          value={sortOption}
          onChange={handleSortChange}
        >
          <option value="VALUE_HIGH">{SortOptions.VALUE_HIGH.label}</option>
          <option value="VALUE_LOW">{SortOptions.VALUE_LOW.label}</option>
          <option value="PRICE_LOW">{SortOptions.PRICE_LOW.label}</option>
          <option value="PRICE_HIGH">{SortOptions.PRICE_HIGH.label}</option>
          <option value="NAME_AZ">{SortOptions.NAME_AZ.label}</option>
          <option value="NAME_ZA">{SortOptions.NAME_ZA.label}</option>
          <option value="BRAND_AZ">{SortOptions.BRAND_AZ.label}</option>
        </select>
      </div>
      
      <div className="hotel-grid">
        {hotels.map((hotel) => (
          <HotelCard key={hotel.id} hotel={hotel} />
        ))}
      </div>
    </div>
  );
};

// Default props
HotelCards.defaultProps = {
  hotels: [],
  currentSortBy: 'valuePerPoint',
  currentSortOrder: 'DESC'
};

export default HotelCards;