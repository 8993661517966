import React, { useState } from 'react';

// Map between brand codes in the database and brand names
const BRAND_NAME_MAP = {
  'WA': 'Waldorf Astoria',
  'CH': 'Conrad',
  'LX': 'LXR',
  'HI': 'Hilton',
  'QQ': 'Curio Collection',
  'DT': 'DoubleTree',
  'UP': 'Tapestry Collection',
  'ES': 'Embassy Suites',
  'GI': 'Hilton Garden Inn',
  'HP': 'Hampton',
  'RU': 'Tru',
  'HW': 'Homewood Suites',
  'HT': 'Home2 Suites',
  'GV': 'Hilton Grand Vacations',
  'OU': 'Motto',
  'PE': 'Spark',
  'SA': 'Signia',
  'PY': 'Canopy',
  'GU': 'DoubleTree Suites'
};

const HotelCard = ({ hotel }) => {
  const [expanded, setExpanded] = useState(false);
  
  // Debug output
  console.log('HotelCard rendering with data:', hotel);
  
  // Check if we're getting data in old or new format
  const isOldFormat = hotel.cashPrice !== undefined && hotel.searchDate !== undefined;
  
  // Extract all the data with defaults
  const {
    name = 'Unknown Hotel',
    city = 'Unknown City',
    state = '',
    brand = '',
    dates = [],
    avgValuePerPoint = null,
    valuePerPoint = null, // Old format might have this
    minCashPrice = null,
    maxCashPrice = null
  } = hotel || {};
  
  // Get full brand name from the code
  const fullBrandName = BRAND_NAME_MAP[brand] || brand;

  // Get the value per point (either avg from dates or direct value)
  const effectiveValuePerPoint = avgValuePerPoint !== null ? avgValuePerPoint : valuePerPoint;
  
  // Calculate price range if we have min/max prices
  let priceRange = null;
  if (minCashPrice !== null && maxCashPrice !== null) {
    priceRange = {
      min: typeof minCashPrice === 'number' ? minCashPrice : parseFloat(minCashPrice || 0),
      max: typeof maxCashPrice === 'number' ? maxCashPrice : parseFloat(maxCashPrice || 0)
    };
  } else if (dates && dates.length > 0) {
    // Calculate from dates if available
    const prices = dates.map(d => parseFloat(d.cashPrice)).filter(p => !isNaN(p));
    if (prices.length > 0) {
      priceRange = {
        min: Math.min(...prices),
        max: Math.max(...prices)
      };
    }
  }
  
  // Format values for display
  const formattedValuePerPoint = effectiveValuePerPoint ? 
    (typeof effectiveValuePerPoint === 'number' ? 
      effectiveValuePerPoint.toFixed(3) : effectiveValuePerPoint) : null;
  
  return (
    <div id={`hotel-${hotel.id}`} className="hotel-card">
      <div className="hotel-header">
        <h3>{name}</h3>
        <p className="location">{city}{state ? `, ${state}` : ''}</p>
        {brand && <p className="brand">{fullBrandName}</p>}
      </div>
      
      <div className="price-summary">
        <div className="price cash">
          <span className="label">Cash:</span> 
          <span className="value">
            {priceRange ? (
              priceRange.min === priceRange.max ? 
                `$${priceRange.min.toFixed(2)}` : 
                `$${priceRange.min.toFixed(2)} - $${priceRange.max.toFixed(2)}`
            ) : 'N/A'}
          </span>
        </div>
        
        {formattedValuePerPoint ? (
          <div className="price value">
            <span className="label">Value:</span>
            <span className="value">${formattedValuePerPoint}/1K points</span>
          </div>
        ) : (
          <div className="price points">
            <span className="label">Points:</span>
            <span className="value">N/A</span>
          </div>
        )}
      </div>
      
      {Array.isArray(dates) && dates.length > 0 ? (
        <>
          <button 
            className={`toggle-dates ${expanded ? 'expanded' : ''}`}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Hide Dates' : `Show ${dates.length} Dates`}
          </button>
          
          {expanded && (
            <div className="date-details">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Cash</th>
                    <th>Points</th>
                    <th>Value/1K</th>
                  </tr>
                </thead>
                <tbody>
                  {dates.map((date, index) => {
                    // Debug logging for date format issues
                    console.log(`Row ${index} date:`, date);
                    
                    return (
                      <tr key={date.searchDate || index}>
                        <td>
                          {date.searchDate 
                            ? new Date(date.searchDate).toLocaleDateString() 
                            : 'N/A'}
                        </td>
                        <td>
                          ${typeof date.cashPrice === 'number' 
                             ? date.cashPrice.toFixed(2) 
                             : (parseFloat(date.cashPrice || '0') || 0).toFixed(2)}
                        </td>
                        <td>
                          {date.pointsPrice 
                            ? Number(date.pointsPrice).toLocaleString() 
                            : 'N/A'}
                        </td>
                        <td>
                          {date.valuePerPoint 
                            ? `$${typeof date.valuePerPoint === 'number' 
                                ? date.valuePerPoint.toFixed(3) 
                                : (parseFloat(date.valuePerPoint || '0') || 0).toFixed(3)}` 
                            : 'N/A'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <p className="no-dates-message">No date information available</p>
      )}
    </div>
  );
};

export default HotelCard;