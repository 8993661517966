import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Map between brand codes in the database and brand names
const BRAND_NAME_MAP = {
  'WA': 'Waldorf Astoria',
  'CH': 'Conrad',
  'LX': 'LXR',
  'HI': 'Hilton',
  'QQ': 'Curio Collection',
  'DT': 'DoubleTree',
  'UP': 'Tapestry Collection',
  'ES': 'Embassy Suites',
  'GI': 'Hilton Garden Inn',
  'HP': 'Hampton',
  'RU': 'Tru',
  'HW': 'Homewood Suites',
  'HT': 'Home2 Suites',
  'GV': 'Hilton Grand Vacations',
  'OU': 'Motto',
  'PE': 'Spark',
  'SA': 'Signia',
  'PY': 'Canopy',
  'GU': 'DoubleTree Suites'
};

// Loading overlay for map updates
const MapLoadingOverlay = () => {
  const map = useMap();
  const containerRef = useRef(null);
  
  useEffect(() => {
    if (!map || !containerRef.current) return;
    
    // Create overlay and add to map panes
    const overlay = L.DomUtil.create('div', 'map-loading-overlay');
    overlay.innerHTML = '<div class="map-loading-indicator">Updating results...</div>';
    containerRef.current.appendChild(overlay);
    
    return () => {
      if (containerRef.current && overlay) {
        containerRef.current.removeChild(overlay);
      }
    };
  }, [map]);
  
  return <div ref={containerRef} className="map-overlay-container" />;
};

// Error boundary for map component
class MapErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Map error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="map-error">
          <p>Error loading map. Please try refreshing the page.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

// Fix the marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
});

// Custom icon based on value per point
const createPriceIcon = (price, valuePerPoint) => {
  // Handle null or undefined values
  if (!valuePerPoint) {
    return L.divIcon({
      className: 'custom-value-icon',
      html: `<div class="marker-price gray">N/A</div>`,
      iconSize: [40, 40],
      iconAnchor: [20, 40],
    });
  }
  
  // Parse and format the value to 1 decimal place
  const value = parseFloat(valuePerPoint);
  const formattedValue = value.toFixed(1);
  
  // Color based on value per point (cpp)
  // Green: High value (>= 0.8 cpp)
  // Orange: Medium value (0.5-0.8 cpp)
  // Red: Low value (< 0.5 cpp)
  const color = value >= 0.8 ? 'green' : value >= 0.5 ? 'orange' : 'red';
  
  return L.divIcon({
    className: 'custom-value-icon',
    html: `<div class="marker-price ${color}">${formattedValue}</div>`,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  });
};

// Map event handler component
const MapEventsHandler = ({ onBoundsChange }) => {
  const map = useMap();
  const lastZoomRef = useRef(null);
  const lastMoveTimeRef = useRef(0);
  
  useEffect(() => {
    if (!map) return;
    
    // Initialize lastZoomRef when map is first available
    lastZoomRef.current = map.getZoom();
    
    const handleMoveEnd = () => {
      const now = Date.now();
      const timeSinceLastMove = now - lastMoveTimeRef.current;
      
      // Throttle rapid consecutive movements (e.g., when using keyboard arrows)
      if (timeSinceLastMove < 200) {
        console.log('Ignoring rapid consecutive move event');
        return;
      }
      
      // Update the last move time
      lastMoveTimeRef.current = now;
      
      // Check if zoom level has changed significantly (which is more important than panning)
      const currentZoom = map.getZoom();
      const zoomChanged = lastZoomRef.current !== currentZoom;
      
      // Get the current bounds
      const bounds = map.getBounds();
      const boundsData = {
        north: bounds.getNorth(),
        south: bounds.getSouth(),
        east: bounds.getEast(),
        west: bounds.getWest(),
      };
      
      // For significant zoom changes, always update
      if (zoomChanged) {
        console.log(`Zoom changed from ${lastZoomRef.current} to ${currentZoom}, triggering update`);
        lastZoomRef.current = currentZoom;
        onBoundsChange(boundsData);
        return;
      }
      
      // For normal panning, let the debounce and significance check handle it
      onBoundsChange(boundsData);
    };
    
    // Apply event handlers to appropriate map events
    map.on('moveend', handleMoveEnd);
    map.on('zoomend', handleMoveEnd);
    
    return () => {
      map.off('moveend', handleMoveEnd);
      map.off('zoomend', handleMoveEnd);
    };
  }, [map, onBoundsChange]);
  
  return null;
};

const HotelMap = ({ hotels, onBoundsChange }) => {
  const [selectedHotel, setSelectedHotel] = useState(null);
  const mapRef = useRef(null);
  const debounceTimerRef = useRef(null);
  const lastBoundsRef = useRef(null);
  const [updatingMap, setUpdatingMap] = useState(false);

  // Default center if no hotels provided
  const defaultCenter = [37.0902, -95.7129]; // Center of US
  const defaultZoom = 4;

  // Calculate map center based on hotels
  const findMapCenter = useCallback(() => {
    if (!hotels || hotels.length === 0) return defaultCenter;
    
    // Find average lat/lng of all hotels with valid coordinates
    const hotelsWithCoords = hotels.filter(hotel => {
      if (!hotel.latitude || !hotel.longitude) return false;
      
      const lat = parseFloat(hotel.latitude);
      const lng = parseFloat(hotel.longitude);
      
      return !isNaN(lat) && !isNaN(lng) && 
             lat >= -90 && lat <= 90 && 
             lng >= -180 && lng <= 180;
    });
    
    if (hotelsWithCoords.length === 0) return defaultCenter;
    
    // Calculate average position
    const sumLat = hotelsWithCoords.reduce((sum, hotel) => sum + parseFloat(hotel.latitude), 0);
    const sumLng = hotelsWithCoords.reduce((sum, hotel) => sum + parseFloat(hotel.longitude), 0);
    
    // Set a minimum zoom if only a few hotels
    if (hotelsWithCoords.length <= 3 && mapRef.current) {
      setTimeout(() => {
        if (mapRef.current) mapRef.current.setZoom(10);
      }, 100);
    }
    
    return [sumLat / hotelsWithCoords.length, sumLng / hotelsWithCoords.length];
  }, [hotels, mapRef]);

  // Helper function to check if bounds have changed significantly
  const hasBoundsChangedSignificantly = useCallback((newBounds, oldBounds) => {
    if (!oldBounds) return true; // If no previous bounds, always update
    
    try {
      // Parse old bounds if it's a string
      const prevBounds = typeof oldBounds === 'string' ? JSON.parse(oldBounds) : oldBounds;
      
      // Calculate percentage change in each direction
      const latitudeDiff = Math.abs(newBounds.north - prevBounds.north) + 
                           Math.abs(newBounds.south - prevBounds.south);
      const longitudeDiff = Math.abs(newBounds.east - prevBounds.east) + 
                            Math.abs(newBounds.west - prevBounds.west);
      
      // Calculate the total view area
      const latitudeSpan = Math.abs(newBounds.north - newBounds.south);
      const longitudeSpan = Math.abs(newBounds.east - newBounds.west);
      
      // Calculate percentage change relative to view size
      const latitudePercentChange = (latitudeDiff / latitudeSpan) * 100;
      const longitudePercentChange = (longitudeDiff / longitudeSpan) * 100;
      
      // Only update if the view has changed by more than 10% in either direction
      const hasChangedSignificantly = latitudePercentChange > 10 || longitudePercentChange > 10;
      
      console.log('Map bounds change analysis:', {
        latitudePercentChange: latitudePercentChange.toFixed(2) + '%',
        longitudePercentChange: longitudePercentChange.toFixed(2) + '%',
        hasChangedSignificantly
      });
      
      return hasChangedSignificantly;
    } catch (e) {
      console.error('Error comparing bounds:', e);
      return true; // On error, default to updating
    }
  }, []);

  // Debounced bounds change handler
  const handleBoundsChange = useCallback((bounds) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Store current bounds to compare
    const currentBounds = JSON.stringify(bounds);
    const lastBounds = lastBoundsRef.current;
    
    // Skip if bounds haven't changed significantly (reduces unnecessary API calls)
    if (!hasBoundsChangedSignificantly(bounds, lastBounds)) {
      console.log('Bounds have not changed significantly, skipping update');
      return;
    }
    
    // Show updating indicator
    setUpdatingMap(true);
    
    debounceTimerRef.current = setTimeout(() => {
      // Validate bounds before triggering search
      if (bounds && 
          typeof bounds.north === 'number' && bounds.north >= -90 && bounds.north <= 90 &&
          typeof bounds.south === 'number' && bounds.south >= -90 && bounds.south <= 90 &&
          typeof bounds.east === 'number' && bounds.east >= -180 && bounds.east <= 180 &&
          typeof bounds.west === 'number' && bounds.west >= -180 && bounds.west <= 180) {
        
        // Store these bounds for comparison later
        lastBoundsRef.current = currentBounds;
        
        onBoundsChange(bounds);
        
        // Hide updating indicator after a short delay (gives time for update to start)
        setTimeout(() => {
          setUpdatingMap(false);
        }, 5000); // 5 seconds max showing the indicator
      } else {
        setUpdatingMap(false);
      }
    }, 1500); // Increased to 1.5 seconds for better debounce performance
  }, [onBoundsChange, hasBoundsChangedSignificantly]);
  
  // Function to get the map instance when it's ready
  const setMapRef = useCallback((node) => {
    if (node !== null) {
      mapRef.current = node;
      setMapLoading(false);
      console.log('Map loaded successfully');
    }
  }, []);

  useEffect(() => {
    // On view mode change, trigger a map resize event after small delay
    if (mapRef.current) {
      setTimeout(() => {
        mapRef.current.invalidateSize();
      }, 100);
    }
  }, [mapRef]);

  // Calculate the number of hotels with coordinates
  const hotelsWithCoordinates = hotels.filter(hotel => {
    // Check both latitude and longitude exist and are valid numbers
    if (!hotel.latitude || !hotel.longitude) {
      console.log(`Hotel ${hotel.id} (${hotel.name}) missing coordinates`);
      return false;
    }
    
    const lat = parseFloat(hotel.latitude);
    const lng = parseFloat(hotel.longitude);
    
    const isValid = !isNaN(lat) && !isNaN(lng) && 
                   lat >= -90 && lat <= 90 && 
                   lng >= -180 && lng <= 180;
                   
    if (!isValid) {
      console.log(`Hotel ${hotel.id} (${hotel.name}) has invalid coordinates: ${hotel.latitude}, ${hotel.longitude}`);
    }
    
    return isValid;
  });

  // Map loading state
  const [mapError, setMapError] = useState(false);
  const [mapLoading, setMapLoading] = useState(true);
  
  useEffect(() => {
    // Check if Leaflet is loaded after component mounts
    const checkLeaflet = setTimeout(() => {
      if (!window.L) {
        console.error("Leaflet not loaded");
        setMapError(true);
      }
    }, 2000);
    
    return () => clearTimeout(checkLeaflet);
  }, []);

  return (
    <div className="hotel-map">
      {mapError && (
        <div className="map-error">
          <p>There was a problem loading the map. Try refreshing the page.</p>
          <p>If the issue persists, check that JavaScript is enabled in your browser.</p>
        </div>
      )}
      
      {mapLoading && !mapError && (
        <div className="map-loading">
          <p>Loading map...</p>
        </div>
      )}
      
      {hotelsWithCoordinates.length === 0 ? (
        <div className="no-coordinates-message">
          No hotels with coordinates found. Try adjusting your search.
        </div>
      ) : (
        <>
          {hotelsWithCoordinates.length > 200 && (
            <div className="many-hotels-info">
              {hotelsWithCoordinates.length} hotels found. Consider refining your search area for better performance.
              <button onClick={() => {
                if (mapRef.current) {
                  const bounds = mapRef.current.getBounds();
                  if (bounds) {
                    handleBoundsChange({
                      north: bounds.getNorth(),
                      south: bounds.getSouth(),
                      east: bounds.getEast(),
                      west: bounds.getWest()
                    });
                  }
                }
              }} className="refine-search-button">
                Refresh Map
              </button>
            </div>
          )}
          <MapErrorBoundary>
            <MapContainer
              center={findMapCenter()}
              zoom={defaultZoom}
              style={{ height: '600px', width: '100%' }}
              ref={setMapRef}
            >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <MapEventsHandler onBoundsChange={handleBoundsChange} />
          {updatingMap && <MapLoadingOverlay />}
          
          {/* Show all hotel markers without limiting */}
          {hotelsWithCoordinates.map(hotel => (
            <Marker
              key={hotel.id}
              position={[parseFloat(hotel.latitude), parseFloat(hotel.longitude)]}
              icon={createPriceIcon(hotel.minCashPrice || 0, hotel.avgValuePerPoint)}
              eventHandlers={{
                click: () => setSelectedHotel(hotel),
              }}
            >
              <Popup maxWidth="400">
                <div className="hotel-popup">
                  <h3>{hotel.name}</h3>
                  <p className="popup-location">{hotel.city}, {hotel.state}</p>
                  <p className="popup-brand">{hotel.brand ? (BRAND_NAME_MAP[hotel.brand] || hotel.brand) : 'Unknown'}</p>
                  
                  {/* Value per point - highlighted section */}
                  {hotel.avgValuePerPoint ? (
                    <div className="popup-value-highlight">
                      <strong>Value per Point:</strong> 
                      <span className="popup-value">${parseFloat(hotel.avgValuePerPoint).toFixed(2)}</span>
                      <span className="popup-value-label">cents/point</span>
                    </div>
                  ) : (
                    <p>Value per Point: N/A</p>
                  )}
                  
                  {hotel.minCashPrice && hotel.maxCashPrice ? (
                    <p>Cash Price: ${Math.round(hotel.minCashPrice)} - ${Math.round(hotel.maxCashPrice)}</p>
                  ) : (
                    <p>Cash Price: N/A</p>
                  )}
                  
                  {/* Add date details section */}
                  {hotel.dates && hotel.dates.length > 0 && (
                    <div className="popup-dates">
                      <h4>Available Dates</h4>
                      <div className="popup-dates-table">
                        <table>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Cash</th>
                              <th>Points</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {hotel.dates.slice(0, 5).map((date, idx) => (
                              <tr key={idx}>
                                <td>{new Date(date.searchDate).toLocaleDateString()}</td>
                                <td>${parseFloat(date.cashPrice).toFixed(0)}</td>
                                <td>{date.pointsPrice ? Number(date.pointsPrice).toLocaleString() : 'N/A'}</td>
                                <td>${date.valuePerPoint ? parseFloat(date.valuePerPoint).toFixed(2) : 'N/A'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {hotel.dates.length > 5 && (
                          <div className="popup-more-dates">
                            +{hotel.dates.length - 5} more dates
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  
                  <button onClick={() => {
                    // First, try to find the hotel card directly
                    const element = document.getElementById(`hotel-${hotel.id}`);
                    if (element) {
                      // If the element exists, scroll to it
                      element.scrollIntoView({ behavior: 'smooth' });
                      
                      // Add a highlight effect to make it easier to see
                      element.classList.add('highlight-card');
                      setTimeout(() => {
                        element.classList.remove('highlight-card');
                      }, 2000);
                    } else {
                      // If we can't find the element, it might be because we're in map view
                      // Switch to list view first, then after a short delay, try to find it again
                      const mapApp = document.querySelector('.App');
                      const listViewBtn = mapApp.querySelector('.view-toggle button:first-child');
                      
                      if (listViewBtn) {
                        // Switch to list view by clicking the button
                        listViewBtn.click();
                        
                        // Wait for the switch to complete, then find and scroll to element
                        setTimeout(() => {
                          const hotelElement = document.getElementById(`hotel-${hotel.id}`);
                          if (hotelElement) {
                            hotelElement.scrollIntoView({ behavior: 'smooth' });
                            hotelElement.classList.add('highlight-card');
                            setTimeout(() => {
                              hotelElement.classList.remove('highlight-card');
                            }, 2000);
                          }
                        }, 300);
                      }
                    }
                  }}>
                    Switch to List View
                  </button>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
        </MapErrorBoundary>
        </>
      )}
    </div>
  );
};

export default HotelMap;