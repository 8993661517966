import React, { useState, useEffect, useCallback, createContext } from 'react';
import SearchForm from './components/SearchForm';
import HotelCards from './components/HotelCards';
import HotelMap from './components/HotelMap';
import ThemeToggle from './components/ThemeToggle';
import './App.css';

// Create a context for sharing sort state between components
export const SortContext = createContext({
  sortBy: 'valuePerPoint',
  sortOrder: 'DESC',
  updateSort: () => {}
});

// Debug function to test API connectivity, with multiple fallbacks
async function testApiConnection() {
  // We'll try different approaches to test the connection
  console.log('Running API connectivity tests...');
  
  // Try GET health endpoint first (simplest)
  try {
    console.log('Test 1: GET /health endpoint via proxy');
    const healthResponse = await fetch('/health');
    if (healthResponse.ok) {
      const healthData = await healthResponse.json();
      console.log('Health endpoint response:', healthData);
      return true;
    } else {
      console.log('Health endpoint failed with status:', healthResponse.status);
    }
  } catch (err) {
    console.log('Health endpoint error:', err.message);
  }
  
  // Try GET test endpoint
  try {
    console.log('Test 2: GET /api/test endpoint via proxy');
    const getTestResponse = await fetch('/api/test');
    if (getTestResponse.ok) {
      const getTestData = await getTestResponse.json();
      console.log('GET test endpoint response:', getTestData);
      return true;
    } else {
      console.log('GET test endpoint failed with status:', getTestResponse.status);
    }
  } catch (err) {
    console.log('GET test endpoint error:', err.message);
  }
  
  // Try direct URLs (bypassing proxy)
  try {
    console.log('Test 3: GET direct URL to health endpoint');
    const directHealthResponse = await fetch('http://localhost:3001/health');
    if (directHealthResponse.ok) {
      const directHealthData = await directHealthResponse.json();
      console.log('Direct health endpoint response:', directHealthData);
      return true;
    } else {
      console.log('Direct health endpoint failed with status:', directHealthResponse.status);
    }
  } catch (err) {
    console.log('Direct health endpoint error:', err.message);
  }
  
  // Final attempt with POST
  try {
    console.log('Test 4: POST to test endpoint via direct URL');
    const testPayload = { 
      startDate: '2025-03-01', 
      endDate: '2025-03-02',
      cities: []
    };
    console.log('Testing API with payload:', testPayload);
    
    const response = await fetch('http://localhost:3001/api/test', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(testPayload)
    });
    
    if (response.ok) {
      const data = await response.json();
      console.log('Test API response:', data);
      return data.success;
    } else {
      console.log('POST test endpoint failed with status:', response.status);
    }
  } catch (err) {
    console.log('POST test endpoint error:', err.message);
  }
  
  // If all tests failed
  console.error('All API connectivity tests failed');
  return false;
}

function App() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchCities, setSearchCities] = useState('');
  const [apiConnected, setApiConnected] = useState(null);
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'map'
  const [searchParams, setSearchParams] = useState({
    startDate: '',
    endDate: '',
    cities: '',
    sortBy: 'valuePerPoint',
    sortOrder: 'DESC',
    minCashPrice: null,
    maxCashPrice: null,
    brands: [],
    mapBounds: null
  });
  
  // Search function definition
  const searchHotels = useCallback(async (startDate, endDate, cities, options = {}) => {
    if (!startDate || !endDate) {
      setError('Please provide start and end dates');
      return;
    }
    
    // Store the cities in state so we can reference it in the UI
    setSearchCities(cities || '');
    
    // Only show loading state for certain types of operations
    const isSortChange = options.updateType === 'sort';
    const isMapBoundsChange = options.updateType === 'mapBounds';
    
    // Don't show the loading spinner for sort changes or map bounds changes
    // For map bounds changes, we'll handle loading differently
    if (!isSortChange && !isMapBoundsChange) {
      setLoading(true);
    }
    
    // For map bounds changes, show a more subtle loading indicator
    if (isMapBoundsChange) {
      // Just log it and potentially use a less intrusive indicator
      console.log('Map bounds update in progress...');
    }
    
    setError(null);
    
    // Don't send empty/null filter values to reduce payload size and processing
    const cleanOptions = {};
    
    // Calculate sort options
    const currentSortBy = options.sortBy || (options.preserveSort ? searchParams.sortBy : null);
    const currentSortOrder = options.sortOrder || (options.preserveSort ? searchParams.sortOrder : null);
    
    if (currentSortBy) cleanOptions.sortBy = currentSortBy;
    if (currentSortOrder) cleanOptions.sortOrder = currentSortOrder;
    
    // Include map bounds if provided
    if (options.mapBounds) {
      cleanOptions.mapBounds = options.mapBounds;
      cleanOptions.mapSearch = true;
    }
    
    // Update URL with search parameters to support browser history
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('startDate', startDate);
    urlSearchParams.set('endDate', endDate);
    if (cities && cities.trim()) urlSearchParams.set('cities', cities);
    if (options.minCashPrice) urlSearchParams.set('minCashPrice', options.minCashPrice);
    if (options.maxCashPrice) urlSearchParams.set('maxCashPrice', options.maxCashPrice);
    if (options.brands && options.brands.length > 0) urlSearchParams.set('brands', options.brands.join(','));
    
    // Include sort settings in URL
    if (cleanOptions.sortBy) urlSearchParams.set('sortBy', cleanOptions.sortBy);
    if (cleanOptions.sortOrder) urlSearchParams.set('sortOrder', cleanOptions.sortOrder);
    
    // COMPLETELY DISABLE HISTORY API FOR PRODUCTION
    // This fixes the "SecurityError: The operation is insecure" issue
    
    // We won't use history API at all in this version
    // Store search parameters in sessionStorage instead
    try {
      const searchState = JSON.stringify({
        startDate,
        endDate,
        cities,
        options: { ...options }
      });
      
      // Use session storage instead of history API
      sessionStorage.setItem('currentSearch', searchState);
      console.log('Search state saved to sessionStorage');
    } catch (storageError) {
      console.warn('Error saving search state:', storageError.message);
    }
    
    // No browser history manipulation at all
    
    try {
      // Format city input (comma-separated cities with optional state codes)
      // Optimize by only parsing if search terms actually changed
      const cityList = cities && cities.trim() 
        ? cities.split(',').map(city => city.trim())
        : [];
      
      // Add remaining filter options to our cleanOptions object
      if (options.minCashPrice !== undefined && options.minCashPrice !== null && options.minCashPrice !== '') {
        cleanOptions.minCashPrice = options.minCashPrice;
      }
      if (options.maxCashPrice !== undefined && options.maxCashPrice !== null && options.maxCashPrice !== '') {
        cleanOptions.maxCashPrice = options.maxCashPrice;
      }
      if (options.minPointsPrice !== undefined && options.minPointsPrice !== null && options.minPointsPrice !== '') {
        cleanOptions.minPointsPrice = options.minPointsPrice;
      }
      if (options.maxPointsPrice !== undefined && options.maxPointsPrice !== null && options.maxPointsPrice !== '') {
        cleanOptions.maxPointsPrice = options.maxPointsPrice;
      }
      if (options.brands && options.brands.length > 0) cleanOptions.brands = options.brands;
      
      // Prepare the request payload with only relevant data
      // Sort settings are already included in cleanOptions if needed
      const payload = {
        startDate,
        endDate,
        cities: cityList,
        ...cleanOptions
      };
      
      console.log('Sending search request with payload:', payload);
      
      // Always store the current search parameters with the correct sort settings
      setSearchParams({
        startDate,
        endDate,
        cities,
        // Use the cleaned sort options we calculated earlier (which handles preservation)
        sortBy: cleanOptions.sortBy || searchParams.sortBy,
        sortOrder: cleanOptions.sortOrder || searchParams.sortOrder,
        // Include other options, but remove internal flags
        ...Object.entries(options)
          .filter(([key]) => !['preserveSort', 'updateType'].includes(key))
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
      });
      
      // Try proxy first, then direct URL as fallback
      let response;
      let data;
      
      try {
        // First try with proxy
        response = await fetch('/api/search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        
        data = await response.json();
      } catch (proxyError) {
        console.log('Proxy search failed, trying direct URL:', proxyError);
        
        // Fallback to direct URL
        response = await fetch('http://localhost:3001/api/search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        
        data = await response.json();
      }
      
      if (!response.ok) {
        throw new Error(data.error || `Error: ${response.status}`);
      }
      
      // Debug: Log the complete response structure
      console.log('API Response:', data);
      
      // Check if the response has the expected structure
      if (!data || typeof data !== 'object') {
        console.error('Unexpected API response format:', data);
        throw new Error('Invalid API response format');
      }
      
      // Handle both response formats - the API might return an array directly or {hotels: [...]}
      if (Array.isArray(data)) {
        // Handle array response (old format)
        console.log('Detected array response format with', data.length, 'results');
        
        // Force grouping of hotels by ID to ensure uniqueness
        const hotelMap = {};
        
        data.forEach(item => {
          // Skip items without an ID (shouldn't happen, but just in case)
          if (!item.id) return;
          
          if (!hotelMap[item.id]) {
            // First time seeing this hotel - create a new entry
            hotelMap[item.id] = {
              id: item.id,
              name: item.name,
              city: item.city,
              state: item.state,
              brand: item.brand,
              valuePerPoint: item.valuePerPoint, // Will be recalculated
              dates: [] // Will be populated
            };
          }
          
          // Add this date entry to the hotel
          hotelMap[item.id].dates.push({
            searchDate: item.searchDate,
            cashPrice: item.cashPrice,
            pointsPrice: item.pointsPrice,
            valuePerPoint: item.valuePerPoint
          });
        });
        
        // Calculate aggregates for each hotel
        const groupedHotels = Object.values(hotelMap).map(hotel => {
          // Calculate min/max/avg cash prices
          const cashPrices = hotel.dates
            .map(d => parseFloat(d.cashPrice))
            .filter(p => !isNaN(p) && p > 0);
            
          if (cashPrices.length > 0) {
            hotel.minCashPrice = Math.min(...cashPrices);
            hotel.maxCashPrice = Math.max(...cashPrices);
            hotel.avgCashPrice = cashPrices.reduce((sum, p) => sum + p, 0) / cashPrices.length;
          }
          
          // Calculate average value per point
          const validValues = hotel.dates
            .filter(d => d.valuePerPoint !== null && !isNaN(d.valuePerPoint) && d.valuePerPoint > 0);
            
          if (validValues.length > 0) {
            hotel.avgValuePerPoint = validValues
              .reduce((sum, d) => sum + d.valuePerPoint, 0) / validValues.length;
          } else {
            hotel.avgValuePerPoint = null;
          }
          
          return hotel;
        });
        
        console.log(`Grouped ${data.length} dates into ${groupedHotels.length} unique hotels`);
        
        // Sort by value per point (high to low) by default
        groupedHotels.sort((a, b) => {
          if (a.avgValuePerPoint === null) return 1;
          if (b.avgValuePerPoint === null) return -1;
          return b.avgValuePerPoint - a.avgValuePerPoint;
        });
        
        setResults(groupedHotels);
      } else if (data.hotels && Array.isArray(data.hotels)) {
        // Handle object response with hotels array (new format)
        console.log('Detected object response format with', data.hotels.length, 'results');
        
        // Check if any hotels need to be grouped further (by examining first entry)
        if (data.hotels.length > 0) {
          const firstHotel = data.hotels[0];
          const needsGrouping = firstHotel.searchDate !== undefined && !Array.isArray(firstHotel.dates);
          
          console.log('Response format check:', {
            firstHotelId: firstHotel.id,
            hasSearchDate: !!firstHotel.searchDate,
            hasDates: Array.isArray(firstHotel.dates),
            needsGrouping
          });
          
          if (needsGrouping) {
            // Apply the same grouping logic as for the array format
            const hotelMap = {};
            
            data.hotels.forEach(item => {
              if (!item.id) return;
              
              if (!hotelMap[item.id]) {
                hotelMap[item.id] = {
                  id: item.id,
                  name: item.name,
                  city: item.city,
                  state: item.state,
                  brand: item.brand,
                  valuePerPoint: item.valuePerPoint,
                  dates: []
                };
              }
              
              hotelMap[item.id].dates.push({
                searchDate: item.searchDate,
                cashPrice: item.cashPrice,
                pointsPrice: item.pointsPrice,
                valuePerPoint: item.valuePerPoint
              });
            });
            
            // Calculate aggregates and sort
            const groupedHotels = Object.values(hotelMap).map(hotel => {
              // Add aggregate calculations same as above
              const cashPrices = hotel.dates
                .map(d => parseFloat(d.cashPrice))
                .filter(p => !isNaN(p) && p > 0);
                
              if (cashPrices.length > 0) {
                hotel.minCashPrice = Math.min(...cashPrices);
                hotel.maxCashPrice = Math.max(...cashPrices);
                hotel.avgCashPrice = cashPrices.reduce((sum, p) => sum + p, 0) / cashPrices.length;
              }
              
              const validValues = hotel.dates
                .filter(d => d.valuePerPoint !== null && !isNaN(d.valuePerPoint) && d.valuePerPoint > 0);
                
              if (validValues.length > 0) {
                hotel.avgValuePerPoint = validValues
                  .reduce((sum, d) => sum + d.valuePerPoint, 0) / validValues.length;
              } else {
                hotel.avgValuePerPoint = null;
              }
              
              return hotel;
            });
            
            groupedHotels.sort((a, b) => {
              if (a.avgValuePerPoint === null) return 1;
              if (b.avgValuePerPoint === null) return -1;
              return b.avgValuePerPoint - a.avgValuePerPoint;
            });
            
            console.log(`Grouped ${data.hotels.length} hotel records into ${groupedHotels.length} unique hotels`);
            setResults(groupedHotels);
          } else {
            // Data is already in the right format with dates arrays
            console.log('Data already has the correct format with dates arrays');
            
            // Just ensure all hotels have the required properties
            const hotels = data.hotels.map(hotel => {
              if (!hotel.dates || !Array.isArray(hotel.dates)) {
                hotel.dates = [];
              }
              
              // Add any missing calculated fields
              if (hotel.dates.length > 0 && (hotel.minCashPrice === undefined || hotel.avgValuePerPoint === undefined)) {
                // Calculate missing fields if needed
                const cashPrices = hotel.dates
                  .map(d => parseFloat(d.cashPrice))
                  .filter(p => !isNaN(p) && p > 0);
                  
                if (cashPrices.length > 0 && hotel.minCashPrice === undefined) {
                  hotel.minCashPrice = Math.min(...cashPrices);
                  hotel.maxCashPrice = Math.max(...cashPrices);
                }
                
                if (hotel.avgValuePerPoint === undefined) {
                  const validValues = hotel.dates
                    .filter(d => d.valuePerPoint !== null && !isNaN(d.valuePerPoint) && d.valuePerPoint > 0);
                    
                  if (validValues.length > 0) {
                    hotel.avgValuePerPoint = validValues
                      .reduce((sum, d) => sum + d.valuePerPoint, 0) / validValues.length;
                  }
                }
              }
              
              return hotel;
            });
            
            setResults(hotels);
          }
        } else {
          // No hotels returned
          setResults([]);
        }
      } else {
        console.error('Unrecognized response format:', data);
        throw new Error('Unrecognized API response format');
      }
      
      // Use a callback with useState to ensure we're seeing the updated state
      // Using 'results' directly here will show the previous state, not the one we just set
      setResults(currentResults => {
        console.log('Set results state with', currentResults.length, 'items');
        return currentResults;
      });
    } catch (err) {
      console.error('Search error:', err);
      setError(err.message || 'An error occurred while searching');
      setResults([]);
    } finally {
      setLoading(false);
    }
  }, []);
  
  // Function to retry API connection
  const retryApiConnection = async () => {
    setApiConnected(null); // Set to loading state
    const success = await testApiConnection();
    setApiConnected(success);
    console.log('API connection retry result:', success);
  };
  
  // API connection check
  useEffect(() => {
    testApiConnection().then(success => {
      setApiConnected(success);
      console.log('API connection test result:', success);
    });
  }, []);
  
  // Track when search state changes to handle map view persistence
  useEffect(() => {
    // If this is a map bounds update, preserve the view state
    if (searchParams.updateType === 'mapBounds' && searchParams.preserveView) {
      console.log('Preserving map view state during bounds update');
      // This means we're doing a map-based search, don't reset any UI state
      // The map component will handle its own loading state
    }
  }, [searchParams]);
  
  // Load initial search parameters without using history API
  useEffect(() => {
    try {
      // First, try to load from sessionStorage
      const savedSearch = sessionStorage.getItem('currentSearch');
      if (savedSearch) {
        try {
          const { startDate, endDate, cities, options } = JSON.parse(savedSearch);
          if (startDate && endDate) {
            console.log('Loading search from sessionStorage:', { startDate, endDate, cities });
            // Small delay to ensure component is mounted
            setTimeout(() => {
              searchHotels(startDate, endDate, cities || '', options || {});
            }, 100);
            return; // Skip URL parsing if we have session data
          }
        } catch (parseError) {
          console.warn('Error parsing saved search:', parseError);
          // Continue to URL parsing as fallback
        }
      }
      
      // Fallback to URL parameters (first load only)
      const urlParams = new URLSearchParams(window.location.search);
      const startDate = urlParams.get('startDate');
      const endDate = urlParams.get('endDate');
      const cities = urlParams.get('cities');
      
      if (startDate && endDate) {
        const options = {};
        
        if (urlParams.has('minCashPrice')) options.minCashPrice = urlParams.get('minCashPrice');
        if (urlParams.has('maxCashPrice')) options.maxCashPrice = urlParams.get('maxCashPrice');
        if (urlParams.has('brands')) options.brands = urlParams.get('brands').split(',');
        if (urlParams.has('sortBy')) options.sortBy = urlParams.get('sortBy');
        if (urlParams.has('sortOrder')) options.sortOrder = urlParams.get('sortOrder');
        
        // Slight delay to ensure component is fully mounted
        setTimeout(() => {
          searchHotels(startDate, endDate, cities || '', options);
        }, 100);
      }
      
      // Don't use popstate handler to avoid history API completely
    } catch (error) {
      console.warn('Error in initial load effect:', error.message);
    }
  }, [searchHotels]);

  // Handler for search options (sorting, filtering)
  const handleSearchUpdate = useCallback((options) => {
    // Get current search parameters from state
    const { startDate, endDate, cities, ...otherParams } = searchParams;
    
    if (!startDate || !endDate) {
      console.error('Cannot update search: missing date parameters');
      return;
    }
    
    console.log('Current search params:', searchParams);
    console.log('Updating with options:', options);
    
    // Merge with new options, being careful about null/undefined values
    const updatedOptions = { ...otherParams };
    
    // Only update properties that are explicitly provided
    Object.keys(options).forEach(key => {
      updatedOptions[key] = options[key];
    });
    
    console.log('Final updated options:', updatedOptions);
    
    // Check if this is a map bounds update
    const isMapBoundsChange = options.mapBounds !== undefined;
    
    // Check if this is a sort change
    const isSortChange = options.sortBy !== undefined && 
                         Object.keys(options).every(key => 
                           key === 'sortBy' || key === 'sortOrder' || key === 'offset');
    
    // Pass the updateType to customize behavior
    if (isMapBoundsChange) {
      updatedOptions.updateType = 'mapBounds';
      updatedOptions.preserveView = true; // Prevent page reset
    } else if (isSortChange) {
      updatedOptions.updateType = 'sort';
    } else {
      updatedOptions.updateType = 'filter';
    }
    
    // Re-execute search with updated options
    searchHotels(startDate, endDate, cities, updatedOptions);
  }, [searchParams, searchHotels]);
  
  // Pagination has been removed

  // Create a SortContext provider with current sort state
  const sortContextValue = {
    sortBy: searchParams.sortBy,
    sortOrder: searchParams.sortOrder,
    updateSort: (sortBy, sortOrder) => {
      // This function will be called by components to update sort
      handleSearchUpdate({
        sortBy,
        sortOrder
      });
    }
  };

  return (
    <SortContext.Provider value={sortContextValue}>
      <div className="App">
        <ThemeToggle />
        <main>
          <h1>Hilton Points Analysis</h1>
          
          {apiConnected === null ? (
            <div className="api-status">
              Checking API connection...
            </div>
          ) : apiConnected === false ? (
            <div className="api-error">
              ⚠️ API server connection failed. Is the backend server running?
              <div className="api-error-details">
                Make sure you've started the backend with: <code>make backend</code>
              </div>
              <button 
                className="retry-button" 
                onClick={retryApiConnection}
              >
                Retry Connection
              </button>
            </div>
          ) : null}
          
          <SearchForm 
            onSearch={searchHotels} 
            loading={loading} 
            error={error} 
          />
        
        {loading && results.length === 0 ? (
          <div className="loading">Loading results...</div>
        ) : results && results.length > 0 ? (
          <div className="results">
            <h2>Search Results {!searchCities || !searchCities.trim() ? '(All Cities)' : ''}</h2>
            
            <div className="results-summary">
              Showing {results.length} hotels
            </div>
            
            <div className="view-toggle">
              <button 
                className={viewMode === 'list' ? 'active' : ''} 
                onClick={() => setViewMode('list')}
              >
                List View
              </button>
              <button 
                className={viewMode === 'map' ? 'active' : ''} 
                onClick={() => setViewMode('map')}
              >
                Map View
              </button>
            </div>
            
            {/* Loading indicator removed for cleaner sort experience */}
            
            {/* Double check hotels array before passing to component */}
            {Array.isArray(results) ? (
              viewMode === 'list' ? (
                <HotelCards 
                  hotels={results}
                />
              ) : (
                loading ? (
                  <div className="loading">Loading map data...</div>
                ) : (
                  <HotelMap 
                    hotels={results}
                    onBoundsChange={(bounds) => {
                      // Store the last bounds to avoid unnecessary API calls
                      if (!window.lastMapBounds) {
                        window.lastMapBounds = bounds;
                        console.log('Initial map bounds set:', bounds);
                        handleSearchUpdate({ mapBounds: bounds });
                        return;
                      }
                      
                      // Check if bounds have changed significantly at the App level
                      // as a second layer of protection against unnecessary API calls
                      const prevBounds = window.lastMapBounds;
                      
                      // Skip if identical
                      if (JSON.stringify(prevBounds) === JSON.stringify(bounds)) {
                        console.log('App: Bounds unchanged, skipping API call');
                        return;
                      }
                      
                      // Only update the stored bounds and trigger API call if we're
                      // actually making a search
                      console.log('App: Map bounds changed significantly, updating:', bounds);
                      window.lastMapBounds = bounds;
                      handleSearchUpdate({ mapBounds: bounds });
                    }}
                  />
                )
              )
            ) : (
              <div className="error">Error: Results data is not an array</div>
            )}
            
            {/* Pagination controls removed */}
          </div>
        ) : !loading && !error ? (
          <div className="no-results">No results to display. Please search for hotels.</div>
        ) : null}
      </main>
    </div>
    </SortContext.Provider>
  );
}

export default App;