import React, { useState, useContext } from 'react';
import { SortContext } from '../App';

// Hilton hotel brands - these are the full display names used in filtering
const HILTON_BRANDS = [
  "Waldorf Astoria",
  "Conrad",
  "LXR",
  "Hilton",
  "Curio Collection",
  "DoubleTree",
  "DoubleTree Suites",
  "Tapestry Collection",
  "Canopy",
  "Embassy Suites",
  "Hilton Garden Inn",
  "Hampton",
  "Tru",
  "Homewood Suites",
  "Home2 Suites",
  "Hilton Grand Vacations",
  "Motto",
  "Spark",
  "Signia"
];

const SearchForm = ({ onSearch, loading, error }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [cities, setCities] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [minCashPrice, setMinCashPrice] = useState('');
  const [maxCashPrice, setMaxCashPrice] = useState('');
  const [selectedBrands, setSelectedBrands] = useState([]);
  
  // Access the sort context to get current sort settings
  const sortContext = useContext(SortContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Prevent form submission if already loading
    if (loading) return;
    
    // Prepare filter values
    let minCashPriceValue = null;
    let maxCashPriceValue = null;
    
    // Only pass numeric values for price filters
    if (minCashPrice !== '') {
      const parsed = parseFloat(minCashPrice);
      if (!isNaN(parsed)) {
        minCashPriceValue = parsed;
      }
    }
    
    if (maxCashPrice !== '') {
      const parsed = parseFloat(maxCashPrice);
      if (!isNaN(parsed)) {
        maxCashPriceValue = parsed;
      }
    }
    
    // Build search options, including current sort options from context
    const searchOptions = {
      minCashPrice: minCashPriceValue,
      maxCashPrice: maxCashPriceValue,
      brands: selectedBrands.length > 0 ? selectedBrands : [],
      // Include current sort settings from context
      sortBy: sortContext.sortBy,
      sortOrder: sortContext.sortOrder
    };
    
    console.log('Submitting search with options:', searchOptions);
    console.log('Using sort settings from context:', {
      sortBy: sortContext.sortBy,
      sortOrder: sortContext.sortOrder
    });
    
    // Call the onSearch function to handle the search without page reload
    onSearch(startDate, endDate, cities, searchOptions);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  
  const handleBrandToggle = (brand) => {
    setSelectedBrands(prevSelected => {
      if (prevSelected.includes(brand)) {
        return prevSelected.filter(b => b !== brand);
      } else {
        return [...prevSelected, brand];
      }
    });
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="search-form">
      <h2>Search Hotels</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="start-date">Start Date:</label>
            <input 
              id="start-date" 
              type="date" 
              value={startDate} 
              onChange={(e) => setStartDate(e.target.value)} 
              min={today}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="end-date">End Date:</label>
            <input 
              id="end-date" 
              type="date" 
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={startDate || today}
              required
            />
          </div>
        </div>
        
        <div className="form-group">
          <label htmlFor="cities">
            Cities (comma-separated, e.g. "Miami,FL,New York,NY"):
            <span className="optional-label"> - optional, leave empty to search all cities</span>
          </label>
          <input 
            id="cities" 
            type="text" 
            value={cities}
            onChange={(e) => setCities(e.target.value)}
            placeholder="Miami,FL,New York,NY or leave empty for all cities" 
          />
        </div>
        
        <button 
          type="button" 
          className="filter-toggle" 
          onClick={toggleFilters}
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </button>
        
        {showFilters && (
          <div className="advanced-filters">
            <h3>Filters</h3>
            
            <div className="form-row">
              <div className="form-group half">
                <label htmlFor="min-cash-price">Min Price ($):</label>
                <input 
                  id="min-cash-price" 
                  type="number" 
                  min="0"
                  step="10" 
                  value={minCashPrice}
                  onChange={(e) => setMinCashPrice(e.target.value)}
                  placeholder="Min price" 
                />
              </div>
              
              <div className="form-group half">
                <label htmlFor="max-cash-price">Max Price ($):</label>
                <input 
                  id="max-cash-price" 
                  type="number" 
                  min="0"
                  step="10" 
                  value={maxCashPrice}
                  onChange={(e) => setMaxCashPrice(e.target.value)}
                  placeholder="Max price" 
                />
              </div>
            </div>
            
            <div className="form-group">
              <label>Brands:</label>
              <div className="brand-filters">
                {HILTON_BRANDS.map(brand => (
                  <div key={brand} className="brand-checkbox">
                    <input
                      type="checkbox"
                      id={`brand-${brand.replace(/\s+/g, '-').toLowerCase()}`}
                      checked={selectedBrands.includes(brand)}
                      onChange={() => handleBrandToggle(brand)}
                    />
                    <label htmlFor={`brand-${brand.replace(/\s+/g, '-').toLowerCase()}`}>
                      {brand}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        
        <button 
          type="submit" 
          className="search-button"
          disabled={loading}
          style={{ opacity: loading ? 0.7 : 1 }}
        >
          {loading ? 'Searching...' : 'Search'}
        </button>
        
        {error && <div className="error">{error}</div>}
      </form>
    </div>
  );
};

export default SearchForm;